
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                











































































































.outline-text {
  padding: 0;
}

svg text {
  opacity: 0.35;
  letter-spacing: 3px;
}

svg {
  max-width: 100%;

  @include media-breakpoint-up(md) {
    max-width: calc(100% - 60px);
  }
}
