
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                





















































.not-supported {
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.9);

  z-index: $zindex-no-support;

  &__container {
    background-color: $gray-900;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    width: calc(100% - 40px);
    max-width: 800px;
    padding: 40px 30px;

    @include media-breakpoint-up(sm) {
      padding: 40px 70px;
    }
  }

  .copy {
    margin-bottom: 20px;
  }

  a {
    font-family: var(--wmf-font-inter);
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: none;
  }
}
