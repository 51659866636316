
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                







































.button {
  cursor: pointer;
  transition: color 300ms;
  font-size: 17px;
  padding: 10px;

  &:hover {
    color: $gray-400;
  }
}

.label {
  margin-left: 10px;
  text-transform: uppercase;
}
