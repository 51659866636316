#facm-klaro {
  .klaro {
    .cookie-modal,
    .context-notice,
    .cookie-notice {
      a {
        color: $black;
        font-weight: 600;
        text-decoration: underline;

        &:hover {
          font-weight: bold;
        }
      }

      .cm-btn {
        @extend .btn;
        text-transform: uppercase;
        font-weight: 600;
        display: inline-flex;
        align-items: center;
        background-color: $gray-700;
        border: solid $white 1px;

        &.cn-decline {
          color: $black;
          background-color: $white;
          border-color: $black;
        }
      }
    }
  }
}
