
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                
// Fonts
@import "./fonts/fonts";

// Functions
@import "~bootstrap/scss/functions";

// Variables
@import "./variables";
@import "~bootstrap/scss/variables";

// Merges
$theme-colors: map-merge($theme-colors, $extended-colors);
$spacers: map-merge($spacers, $extended-spacers);

// Utilities
@import "~bootstrap/scss/utilities";
@import "./custom-utilities";

// Mixins
@import "~bootstrap/scss/mixins";

// CSS variables
@import "./root";
@import "~bootstrap/scss/root";

// Reboot
@import "./reboot";
@import "~bootstrap/scss/reboot";

// Grid
@import "~bootstrap/scss/containers";
@import "./containers";
@import "~bootstrap/scss/grid";
@import "./grid";

// Typography
@import "./type";
@import "~bootstrap/scss/type";

// Cards
@import "~bootstrap/scss/card";

// Forms
@import "~bootstrap/scss/forms";

// Buttons
@import "~bootstrap/scss/buttons";

// Utilities
@import "~bootstrap/scss/bootstrap-utilities";
