
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                





























































.online-shop-teaser {
  //width: 100%;
  position: fixed;
  z-index: $zindex-online-shop-teaser;
  overflow: hidden;

  //@include media-breakpoint-up(md) {
  width: calc(100% - var(--wmf-slide-scroll-bar-width));
  //}

  .content {
    //background: linear-gradient(90deg, rgba(53, 158, 210, 0.8) 0%, rgba(53, 158, 210, 0.8) 10%, #000 100%);
    background: linear-gradient(90deg, #025e8e 0%, #025e8e 10%, #000 100%);
    height: 30px;
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: black;
      height: 100%;
      width: 100%;
      z-index: -1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(90deg, $blue 0%, $blue 80%, #235f7e 100%);
      //transform: translate(-100%, 0);
      transition: transform 300ms ease-in, opacity 600ms;
    }
  }

  .container-fluid {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .cta {
    text-decoration: none;
    transition: color 300ms;
    white-space: nowrap;

    &:hover,
    &:focus {
      color: $white;

      .content:after {
        //transform: translateX(0);
        opacity: 0.8;
      }
    }

    .icon {
      margin-right: 8px;
      font-size: 9px;
      flex: 0 0 auto;
    }
  }

  &.back-link {
    .icon {
      transform: rotate(180deg);
    }
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: transform 0.5s ease-out 1.2s;
}

.slide-down-enter, .slide-down-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100%);
}
