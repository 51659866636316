// Prefix for :root variables
$variable-prefix: wmf-;

// Theme-color variables
$white: #fff;
$black: #000;
$blue: #359ed2;
$brown: #795548;
$brown-100: #5d4037;
$red-100: #fd8888;
$gray-50: #d8d8d8;
$gray-100: #b9b9b9;
$gray-300: #808080;
$gray-400: #6f6f6f;
$gray-500: #434343;
$gray-600: #2e2e2e;
$gray-700: #1d1d1b;
$gray-800: #1a1a1a;
$gray-900: #151514;
$info: $blue;

$extended-colors: (
  "white": $white,
  "blue": $blue,
  "brown": $brown,
  "brown-100": $brown-100,
  "red-100": $red-100,
  "gray-100": $gray-100,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
);

// container
$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 100%,
  xl: 100%,
  xxl: 100%,
);
$container-padding-x: 33px;

// grid
$grid-columns: 12;
$grid-gutter-width: 14px;

// Body
$body-bg: $black;
$body-color: $white;

// Typography
$font-family-inter: "Inter", "Helvetica Neue", Arial, sans-serif;
$font-family-inter-var: "Inter var", "Helvetica Neue", Arial, sans-serif;
$font-family-wmfrotis: "WMFRotis", "Helvetica Neue", Arial, sans-serif;
$font-family-wmfrotis-greek: "Rotis Sans Serif Pro Greek", "Helvetica Neue", Arial, sans-serif;
$font-family-base: $font-family-inter;
$headings-font-family: var(--#{$variable-prefix}font-wmfrotis);
$font-size-sm: 11px;
$h1-font-size: 80px;
$h2-font-size: 60px;
$h3-font-size: 60px;
$headings-line-height: 1.16;
$headings-font-weight: bold;
$display-font-sizes: (
  200: 200px,
  167: 167px,
  140: 140px,
);
$display-font-weight: bold;
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

//Links
$link-color: $white;

// Card
$card-bg: $gray-900;
$card-border-radius: 0;
$card-border-width: 0;
$card-spacer-y: 0;
$card-spacer-x: 0;
$card-cap-bg: 0;
$card-title-spacer-y: 20px;

// Button
$btn-border-radius: 9px;
$btn-border-width: 1.5px;
$btn-padding-y: 17px;
$btn-padding-x: 30px;
$btn-focus-box-shadow: none;
$btn-focus-width: 0;
$btn-font-size: 11px;

// Forms
$input-bg: $gray-600;
$input-btn-focus-box-shadow: none;
$form-label-font-size: 11px;
$form-label-margin-bottom: 0;
$form-label-margin-top: 10px;
$input-border-width: 0;
$input-border-radius: 6px;
$input-focus-box-shadow: none;
$input-focus-bg: $gray-500;
$input-color: $gray-100;
$input-font-size: 20px;
$input-padding-y: 10px;
$input-padding-x: 25px;
$input-placeholder-color: $gray-100;
$form-check-input-border: 2px solid $white;
$form-check-input-bg: none;
$form-check-input-checked-bg-color: $white;
$form-check-input-focus-border: $white;
$form-check-input-border-radius: 3px;
$form-check-input-width: 21px;
$form-check-padding-start: 32px;
$form-check-input-focus-box-shadow: none;
$form-check-label-cursor: pointer;
$form-check-inline-margin-end: 15px;
$form-check-margin-bottom: 0;
$form-check-input-checked-bg-image: none;
$form-check-radio-checked-bg-image: none;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 655px,
  lg: 992px,
  xl: 1200px,
  xxl: 1441px,
  xxxl: 1500px,
);

// Spacing
$extended-spacers: (
  15px: 15px,
  20px: 20px,
  30px: 30px,
  40px: 40px,
  70px: 70px,
);

$spacing-detail-xxs: 10px;
$spacing-detail-xs: 16px;
$spacing-detail-s: 20px;
$spacing-detail-m: 40px;
$spacing-detail-l: 60px;
$spacing-detail-xl: 80px;
$spacing-detail-xxl: 100px;

// footer
$footer-height: 147px;
$footer-desktop-height: 192px;
$footer-mobile-padding: 60px 81px 40px 36px;
$footer-large-padding: 60px 200px;

// navigation
$navi-item-height: 30px;
$navi-wrapper-height: calc(5 * #{$navi-item-height});
$navi-width: 250px;
$navi-height: calc(11 * #{$navi-item-height});
$navi-circle-s: 6px;
$navi-circle-m: 10px;
$navi-circle-l: 18px;
$navi-meta-height: 20px;
$navi-spacer-height: 50%;

// modal
$modal-height: 600px;
$modal-width: 1200px;
$modal-mask-z-index: 9998;
$modal-container-z-index: 9999;
$modal-option-width: 300px;
$modal-option-height: 75px;
$modal-padding: 20px;
$modal-error-color: red;
$modal-margin: 20px;

//z-index
$zindex-subpagenavigation: 10;
$zindex-buy-button: 51;
$z-index-slider-arrows: 49;
$zindex-header: 91;
$zindex-mobile-navigation: 51;
$zindex-navigation: 90;
$zindex-online-shop-teaser: 91;
$zindex-main-darker: 100;
$zindex-detail-layer: 100;
$zindex-basic-modal: 998;
$zindex-no-support: 999;
$zindex-buy-retailer-layer: 999;
$zindex-lightbox: 999;
$zindex-orientation-notice: 1000;
$zindex-orientation-notice: 1000;

//dropdown
$dropdown-menu-width: 350px;
$dropdown-select-width: 300px;
$dropdown-select-height: 75px;
$dropdown-select-detail-height: 50px;
$dropdown-padding: 20px;

// DetailCarousel
$detail-carousel-arrow-size: 40px;

//product slider
$product-slider-padding-x: 120px;

//new navigation

$navigation-height: 100%;
$navigation-width: 100%;
$navigation-item-height: 30px;
$navigation-wrapper-height: calc(5 * #{$navigation-item-height});
