
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                


















































.footer {
  position: relative;
  background-color: $black;
  color: white;
  display: flex;
  min-height: var(--wmf-footer-height);
  padding: $footer-mobile-padding;
  flex-direction: column;

  a {
    padding: 0 25px;
    text-decoration: none;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    font-size: $font-size-sm;
  }

  @include media-breakpoint-up(lg) {
    justify-content: space-around;

    .meta {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
    }

    .footer-item {
      width: 50%;
    }
  }

  @include media-breakpoint-up(xl) {
    padding: $footer-large-padding;
  }

  @include media-breakpoint-up(xxl) {
    flex-direction: row;

    .meta {
      width: 100%;
    }

    .footer-item {
      width: 25%;
    }
  }
}

.title {
  text-transform: uppercase;
  font-family: var(--wmf-font-wmfrotis);
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-sm;
}

.footer-item {
  padding-right: 30px;
}

.footer-item {
  width: 290px;
  margin-top: $spacing-detail-m;
}

.content {
  margin-top: $spacing-detail-xs;
}

.copy,
.link-item a {
  font-family: var(--wmf-font-inter);
  font-weight: $font-weight-light;
  font-size: 15px;
  text-transform: none;
  padding: 0;
}

.link-item a {
  display: inline-block;
  line-height: 1.5;
}

.social {
  display: flex;
  justify-content: left;
  font-size: 40px;
  line-height: 0;
}
