
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                


























































































































































































































































































































































































































































































































































































































































@mixin indent($side, $amount) {
  //12-col-grid
  margin-#{$side}: calc(100% / (12 / #{$amount}) + 20px);
}

.animation {
  width: 100%;
  height: auto;
  padding-top: var(--wmf-content-offset-y);

  position: relative;
  //max-height: 100vh;
  overflow: hidden;

  // better mobile scroll behavior
  padding-bottom: 100px;

  .has-footer & {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 0px;
  }

  .video {
    position: absolute;
    width: 100vw;
    height: 0;
    padding-top: math.div(100vw, math.div(16, 9));

    video {
      opacity: 0;
    }

    .buffering {
      position: absolute;
      width: 30px;
      height: 30px;

      left: 50%;
      top: 48%;

      transform: translateX(-50%) translateY(-50%);

      z-index: 10;
      opacity: 0;
      visibility: hidden;
    }

    z-index: 2;
  }

  .poster,
  video {
    position: absolute;
    top: var(--wmf-content-offset-y);
    left: 0;
    width: 100%;
    height: auto;
  }

  .poster {
    padding-top: 0 !important;
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    .video {
      z-index: 0;
    }

    .poster,
    video {
      position: absolute;
      //top: 0;
      top: var(--wmf-content-offset-y);
      left: 0;
      width: 100%;
      height: 100%;
      min-height: 414px;
      object-fit: cover;
    }

    .poster {
      min-height: 100%;
      padding-top: 0 !important;
    }
  }

  &--full-video {
    .video {
      position: absolute;
      top: 0 !important;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .poster,
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      object-fit: cover;

      //@include media-breakpoint-down(lg) {
      //width: 170%;
      //left: -35%;
      //object-fit: cover;
      //}
    }

    .poster {
      min-height: 100%;
      padding-top: 0 !important;
    }

    .chapter-wrapper {
      z-index: 0;
    }
  }
}

.wrapper {
  position: absolute;
  opacity: 0;
  display: none;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  min-height: 414px;
  width: 100%;
  //width: 100vw;

  &:last-child {
    display: flex;
    position: relative;
    height: auto;
    max-height: none;
    //min-height: 100vh;
    min-height: calc(100vh - var(--wmf-content-offset-y));
  }

  /*.indent {
        padding-right: 0;
    padding-left: 0;
    margin-left: 0;
  }*/

  .content-container .row {
    flex-direction: row;
  }

  @include media-breakpoint-up(xl) {
    .indent,
    .indent--1 {
      @include indent('left', 1);
    }

    .indent--2 {
      @include indent('left', 2);
    }
  }

  &.center {
    justify-content: flex-start;

    @include media-breakpoint-up(sm) {
      justify-content: center;

      .content-container .row {
        justify-content: center;
      }
      .content {
        width: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      .content {
        margin-top: 205px;
      }
    }
  }

  &.bottom-left-indent {
    align-items: flex-start;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }

    .chapter-wrapper {
      display: flex;
      justify-content: flex-start;
    }
  }

  &.bottom-right-indent {
    align-items: flex-end;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      justify-content: space-between;
    }

    .container-fluid .row {
      width: 100%;
      position: relative;
      justify-content: flex-start;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end;
      }
    }

    .indent {
      margin-left: 0;
    }

    .chapter-wrapper {
      display: flex;
      justify-content: flex-start;

      .chapter {
        max-width: 560px;
        width: 100%;
      }
    }

    @include media-breakpoint-up(lg) {
      .chapter-wrapper {
        justify-content: flex-end;
      }

      .indent,
      .indent--1 {
        width: auto;
        left: unset;
        padding-left: 0;
        padding-right: 0;
        @include indent('right', 1);
      }

      .indent--0 {
        width: 25vw;
        left: unset;
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
      }

      .indent--2 {
        @include indent('right', 2);
      }
    }

    @include media-breakpoint-up(xl) {
      .indent--0 {
        margin-right: 60px;
      }
    }
  }

  &.reverse {
    @include media-breakpoint-down(lg) {
      .content {
        transform: none !important;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: column-reverse;

      .chapter-wrapper {
        margin-top: 0;
        margin-bottom: 10vh;
      }

      .content {
        margin-top: 110px;
        padding-bottom: 0;
      }
    }
  }

  .chapter {
    order: 0;
  }

  .image {
    order: 1;
  }

  .content-container {
    order: 2;

    @include media-breakpoint-down(lg) {
      padding-right: var(--wmf-container-padding-x);
      padding-left: var(--wmf-container-padding-x);

      .row {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  &--flex-start {
    justify-content: flex-start !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 0 var(--wmf-container-padding-x) 0 100px;
  padding: 0;
  z-index: 1;
  position: relative;
  width: auto;

  &--narrow {
    //width: 100%;
    align-self: flex-start;

    .copy {
      padding-top: 30px;
    }

    @include media-breakpoint-up(xxxl) {
      display: none;
    }
  }

  &--has-navigation {
    padding-left: 0px !important;
    padding-right: 0px !important;

    @include media-breakpoint-up(md) {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 0 !important;
      max-width: 25vw;
    }
  }
}

.intro-content {
  width: 100vw;
  display: flex;
  flex-direction: column;

  &.has-copy & {
    z-index: 1;
  }

  .has-editorial-content.has-copy & {
    @include media-breakpoint-up(lg) {
      min-height: calc(100vh - var(--wmf-content-offset-y));
    }
  }
}

.chapter-wrapper {
  position: relative;
  width: 100%;
  left: 0;
  margin-top: 125px;
  z-index: 2;

  &.outline--lines--2,
  .clear-margin & {
    margin-bottom: 0 !important;
  }

  @include media-breakpoint-up(lg) {
    .has-copy & {
      margin-bottom: 50px;
    }
  }

  @include media-breakpoint-up(xl) {
    margin-top: 110px;
    .has-copy & {
      margin-bottom: 20vh;
    }
  }

  @media screen and (min-width: 1200px) and (max-height: 800px) {
    .has-copy & {
      margin-bottom: 10vh;
    }
  }

  @media screen and (min-width: 1200px) and (max-height: 700px) {
    .has-copy & {
      margin-bottom: 5vh;
    }
  }

  .row {
    position: relative;
  }

  &.chapter--flexleft {
    .row {
      justify-content: flex-start !important;
    }

    .indent {
      margin-right: 0;
      @include media-breakpoint-up(xl) {
        @include indent('left', 1);
      }
    }

    .indent--2 {
      @include media-breakpoint-up(xl) {
        @include indent('left', 2);
      }
    }
  }
}

.chapter {
  position: relative;
  top: -32px;
  line-height: 1;
  text-transform: uppercase;
  padding: 0;

  &--align-center {
    bottom: unset !important;
    top: calc(50% - 8px) !important;
    transform: translateY(-50%);
    position: absolute;
  }

  @include media-breakpoint-up(md) {
    top: -60px;
  }

  @include media-breakpoint-up(lg) {
    top: unset;
    position: absolute;
    bottom: 2px;
    white-space: nowrap;

    &--align-top {
      bottom: unset;
      top: 0;
    }
  }
}

.outline {
  position: relative;
  left: 0;
  width: auto !important;
}

.headline {
  flex-grow: 0;

  span {
    opacity: 0;
  }
}

.teaser-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  > * {
    margin-right: 18px;

    &:last-child {
      margin-right: 0;
    }
  }

  & + * {
    margin-top: 26px;
  }
}

.teaser {
  opacity: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.text-teaser {
  color: rgba(255, 255, 255, 0.8);
  border-color: rgba(255, 255, 255, 0.8);
  transition: color 300ms, border-color 300ms;

  @include media-breakpoint-up(lg) {
    max-width: 400px;
  }

  &:hover {
    color: $white;
    border-color: $white;
  }
}

.image-teaser {
  width: 100%;
  display: block;
  margin-bottom: 20px;

  @include media-breakpoint-up(md) {
    width: 280px;
  }

  @include media-breakpoint-up(xl) {
    width: 220px;
  }

  @include media-breakpoint-up(xxl) {
    width: 294px;
  }
}

.copy,
.cta {
  opacity: 0;
  color: rgba(255, 255, 255, 0.8);
}

@include media-breakpoint-up(lg) {
  .content-only-mobile {
    display: none;
  }
}

.image {
  //checken ob das nich mit irgendwas kollidiert
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    min-height: 414px;
  }
}

.content-media {
  position: relative;
  width: 100vw;
  height: auto;
  order: 1;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    order: unset;
  }

  &--hidden {
    opacity: 0 !important;
  }

  &--full {
    position: absolute;
    top: 0;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    .video,
    .image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
    }

    @include media-breakpoint-up(xxxl) {
      object-fit: cover;
      height: 100%;
    }
  }

  &--background {
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-repeat: repeat-y;
    background-size: 100% auto;
  }
}

.has-copy {
  .content-container {
    @include media-breakpoint-up(xl) {
      flex-grow: 1;
    }
  }

  .content {
    padding-top: 26px;
    padding-bottom: 70px;

    @include media-breakpoint-up(lg) {
      padding-bottom: 50px;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 200vw;
        z-index: -1;
        pointer-events: none;
        height: 200vh;
        background: radial-gradient(circle at 50% 50%, #00000085 100px, rgb(0 0 0 / 0%) 50vw);
        transform: translateY(-50%) translateX(-50%);
        opacity: 0.8;
      }
    }
  }

  .headline,
  .copy {
    width: 100%;

    @include media-breakpoint-up(lg) {
      max-width: 560px;
    }
  }

  .headline {
    margin-bottom: 18px;
  }

  .copy {
    margin-bottom: 26px;

    ::v-deep p:first-child {
      margin-bottom: 0;
    }
  }

  .chapter {
    width: 100%;
  }
}

.product-slider__info-container {
  position: relative;

  .copy {
    /*copy duplicate for really large desktops only (xxxl - 1500) - needs no indent*/
    padding-right: var(--wmf-container-padding-x);
    padding-left: var(--wmf-container-padding-x);
    padding-top: var(--wmf-container-padding-x);

    display: none;
  }

  @include media-breakpoint-up(xxxl) {
    //padding-bottom: 240px;

    .copy {
      display: block;
      width: 33%;
      max-width: unset;
      margin-top: 30px;
    }

    .product-slider-info {
      position: absolute;
      top: 0;
    }
  }
}

.animation--skipped {
  .product-slider__info-container {
    @include media-breakpoint-up(xxxl) {
      padding-bottom: 240px;
    }
  }

  .img {
    max-height: unset;
  }
}

.product-slider__container {
  position: relative;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;

  display: flex;
  flex-direction: column;
}

.scrollButton {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  @include media-breakpoint-up(lg) {
    bottom: 30px;
  }
}

.editorial-content {
  pointer-events: none;
  order: 3;
  opacity: 0;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding-bottom: 60px;
  max-width: 1920px;

  &:last-child {
    padding-bottom: 0;
  }

  > *:first-child {
    margin-top: calc(85px + var(--wmf-onlineshop-teaser-height));

    .has-copy & {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      margin-top: calc(110px + var(--wmf-onlineshop-teaser-height));

      .has-copy & {
        margin-top: calc(140px + var(--wmf-onlineshop-teaser-height));
      }
    }
  }
}
