
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                








































































































.slide {
  position: relative;
  width: 100%;
  //overscroll-behavior-y: none;

  //@include media-breakpoint-up(lg) {
  /*removes double scrollbars*/
  height: 100%;
  max-height: 100vh;

  overflow-y: hidden;
  overflow-x: hidden;

  &--scrollable {
    overflow-y: auto;
  }

  //}
}
