
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                



















































































































































.detail-layer {
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: $zindex-detail-layer;
  width: 100%;
  max-width: 655px;
  background-color: $gray-700;

  &.large {
    max-width: 1745px;
  }
}

.detail-content {
  position: relative;
  padding-bottom: $spacing-detail-l;

  .large & {
    padding-bottom: 300px;
  }

  > * {
    margin-top: $spacing-detail-m;

    .large & {
      margin-top: $spacing-detail-xxl;

      &.detail-headline + .detail-image,
      &.detail-headline + .detail-video,
      &.detail-copy + .detail-image,
      &.detail-storefinder,
      &.detail-ar,
      &.detail-copy {
        margin-top: $spacing-detail-m;
      }

      &:first-child {
        &.detail-ar {
          margin-top: 160px;
        }
      }
    }
  }
}

.header-wrapper {
  position: sticky;
  height: 0;
  top: 0;
  left: 0;
  z-index: 10;
}

.header {
  position: relative;
  transition: font-size 300ms;
  font-size: 60px;

  ::v-deep {
    svg {
      width: 1em;
      height: 1em;
    }
  }

  &.small {
    font-size: 20px;

    .button {
      //width: 41px;
      //height: 41px;

      //.icon {
      //  position: absolute;
      //  top: 50%;
      //  left: 50%;
      //  transform: translate(-50%, -50%);
      //}

      &:before {
        transition-delay: 300ms;
        opacity: 1;
      }

      &:hover {
        &:before {
          background-color: $gray-600;
        }
      }
    }

    .back-button {
      font-size: 1em;
      right: 81px;
      top: 40px;
    }

    ::v-deep {
      svg path {
        stroke-width: 4;
      }
    }
  }
}

.button {
  position: absolute;
  top: 40px;
  color: $white;
  background-color: transparent;
  box-shadow: none;
  border: 0;
  margin: 0;
  line-height: 0;
  padding: 10px;
  transition: left 300ms, right 300ms;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 100%;
    z-index: -1;
    left: 50%;
    top: 50%;
    opacity: 0;
    background-color: $black;
    transform: translateX(-50%) translateY(-50%);
    transition: opacity 300ms;
  }

  &:hover {
    color: $gray-400;
  }

  ::v-deep {
    svg path {
      transition: stroke-width 300ms;
      stroke-width: 2;
    }
  }
}

.back-button {
  font-size: 0.6em;
  right: calc(100% + 10px);
  transform: translateX(100%);
  top: 64px;

  ::v-deep {
    svg path {
      stroke-width: 3;
    }
  }
}

.close-button {
  font-size: 1em;
  right: -10px;
}

.outline {
  pointer-events: none;
  font-family: var(--wmf-font-wmfrotis);
  font-weight: $font-weight-bold;
  position: absolute;
  top: 0;
  right: -2.86vw;
  font-size: 15.625vw;
  margin-top: 11.66vw;
  color: transparent;
  text-transform: uppercase;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.6);
  text-stroke: 1px rgba(255, 255, 255, 0.6);
  line-height: 1;
  display: none;
  flex-direction: column;
  align-items: center;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}
