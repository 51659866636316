
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                
// Functions
@import "~bootstrap/scss/functions";

// Variables
@import "./variables";
@import "~bootstrap/scss/variables";

// Buttons
@import "~bootstrap/scss/buttons";

// klaro
@import "./klaro/vars";
@import "~klaro/src/scss/klaro.scss";
@import "./klaro/klaro";
