
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                











































































































.icon-buffering {
  position: fixed;

  top: 50%;
  left: 50%;

  transform: translateX(-50%) translateY(-50%);

  width: 50px;
  height: 50px;

  z-index: 100;
}
