@include media-breakpoint-up(lg) {
  .row {
    --wmf-gutter-x: 16px;
  }
}

.header,
.main {
  --wmf-container-padding-x: 26px;

  @include media-breakpoint-up(lg) {
    --wmf-container-padding-x: 45px;
  }

  @include media-breakpoint-up(xl) {
    --wmf-container-padding-x: 15px;
  }

  .row {
    --wmf-gutter-x: 10px;

    @include media-breakpoint-up(lg) {
      --wmf-gutter-x: 30px;
    }
  }
}

.media-queries-to-js {
  $mediaQueriesString: "";
  @each $breakpoint, $value in $grid-breakpoints {
    $mediaQueriesString: $mediaQueriesString + "/" + $breakpoint + ":" + $value;
  }
  font-family: $mediaQueriesString;
  display: none;
}
