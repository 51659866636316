
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                


































.not-found {
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include media-breakpoint-up(lg) {
    margin-left: calc(100% / (12 / 2) + 20px);
  }
}
