
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                










































































































.btn {
  display: flex;
  border-radius: unset;
}

.button__wrapper {
  display: flex;
  align-items: center;
  text-align: left;
}

.icon {
  font-size: 6.18em;

  &.instagram {
    @include media-breakpoint-down(lg) {
      font-size: 5em;
    }
  }

  //@include media-breakpoint-up(lg) {
  //  font-size: 8.36em;
  //}
}

svg {
  overflow: visible;
}

span {
  display: inline-block;
}
