@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=84b852ce-4219-4066-9c68-85423cfcd2aa");

//Rotis® Sans Serif W04 Greek 45 Light
@font-face {
  font-family: "Rotis Sans Serif Pro Greek";
  src: url("~@/assets/fonts/rotis-sans-serif-greek/6067d073-1376-4c28-8819-6170103b6592.woff2") format("woff2"),
    url("~@/assets/fonts/rotis-sans-serif-greek/b377e8b8-5531-4904-9040-861f8fffa082.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

// Rotis® Sans Serif W04 Greek 55 Regular
@font-face {
  font-family: "Rotis Sans Serif Pro Greek";
  src: url("~@/assets/fonts/rotis-sans-serif-greek/2487b7bd-dde7-4cbb-ada6-db0ef02d268b.woff2") format("woff2"),
    url("~@/assets/fonts/rotis-sans-serif-greek/29f273e8-726d-4e7e-9b6f-d91dd9713d4e.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Rotis® Sans Serif W04 Greek 56 Italic
@font-face {
  font-family: "Rotis Sans Serif Pro Greek";
  src: url("~@/assets/fonts/rotis-sans-serif-greek/e74f9561-be41-43ff-9788-00094e375acf.woff2") format("woff2"),
    url("~@/assets/fonts/rotis-sans-serif-greek/26a9cda5-c0b1-4089-841d-257dba4d065a.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// Rotis® Sans Serif W04 Greek 65 Bold
@font-face {
  font-family: "Rotis Sans Serif Pro Greek";
  src: url("~@/assets/fonts/rotis-sans-serif-greek/0f45f0bb-74ff-4fd0-acbe-ec9066e653c6.woff2") format("woff2"),
    url("~@/assets/fonts/rotis-sans-serif-greek/ceb4451e-25e1-4b53-aa07-a26ffed9c55e.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//  Rotis® Sans Serif W04 Greek 75 Extra Bold
@font-face {
  font-family: "Rotis Sans Serif Pro Greek";
  src: url("~@/assets/fonts/rotis-sans-serif-greek/01c23006-ebaf-46d1-a176-ae9992a053cc.woff2") format("woff2"),
    url("~@/assets/fonts/rotis-sans-serif-greek/4b9c8935-b555-4cb4-b6ae-a0da8059782f.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
