
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                



















































































































.btn {
  transition: color 200ms;

  .icon-before {
    flex-shrink: 0;
    transition: color 200ms, background-color 200ms;
  }

  /* mouse, touch pad */
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: $gray-400;

      .icon-before {
        background-color: $white;
        color: $black;
      }
    }
  }

  &.big {
    font-size: 11px;
    font-weight: bold !important;
    background-color: transparent;
    border: 1px solid white;
    text-transform: none;
    white-space: nowrap;
    border-radius: 30px;
    padding: 14px 20px;
    justify-content: center;

    &:hover {
      color: $black;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &.btn-outline-white {
    &:hover {
      color: $gray-700;
    }
  }
}
