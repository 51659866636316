
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                


























.social-media {
  display: flex;
  margin-top: $spacing-detail-m;
  margin-bottom: $spacing-detail-m;
  justify-content: left;
}

.social-item {
  margin-right: 23px;
}

.social-item:last-child {
  margin-right: 0;
}

@include media-breakpoint-up(lg) {
  .social-media {
    margin-top: $spacing-detail-m;
  }
}

@include media-breakpoint-up(xl) {
  .social-media {
    margin-top: $spacing-detail-m;
  }
}
