
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                















































































.btn {
  font-weight: 900;
  opacity: 0;
  visibility: hidden;
}

.icon {
  font-size: 0.9em;
}
