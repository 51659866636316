
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                





























































.main-debug-grid {
  width: calc(100% - var(--wmf-slide-scroll-bar-width));
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  .container-fluid {
    max-width: 1440px;
  }
}
