strong,
b {
  font-weight: $font-weight-bold;
}

.copy--regular {
  font-size: 15px;

  @include media-breakpoint-up(lg) {
    font-size: 16px;
    //font-size: 18px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 17px;
    //font-size: 20px;
  }
}

.copy--small {
  font-size: 15px;
}

.headline--regular {
  font-size: 30px;

  @include media-breakpoint-up(lg) {
    //font-size: 50px;
    font-size: 36px;
  }

  @include media-breakpoint-up(xxl) {
    //font-size: 60px;
    font-size: 40px;
  }
}

.headline--inter {
  font-family: var(--wmf-font-inter);
  font-weight: $font-weight-semi-bold;

  font-size: 24px;
  line-height: 120%;

  @include media-breakpoint-up(md) {
    font-size: 46px;
  }
}

.headline--small {
  font-size: 26px;

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }
}

.subline--regular,
.tiny-label {
  font-size: 11px;
  font-family: var(--wmf-font-inter);
  font-weight: $font-weight-bold;
}

.chapter {
  font-size: 26px;

  @include media-breakpoint-up(md) {
    font-size: 50px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 70px;
  }

  @include media-breakpoint-up(xxl) {
    font-size: 80px;
  }
}

.medium-label {
  font-size: 14px;
  font-family: var(--wmf-font-inter);
  font-weight: $font-weight-medium;
  text-transform: uppercase;
}

.small-label {
  font-family: var(--wmf-font-wmfrotis);
  text-transform: uppercase;
  //font-size: 19px;
  font-size: 16px;
}

.detail-layer {
  .headline {
    font-family: var(--wmf-font-wmfrotis);
    font-weight: $font-weight-bold;
    font-size: 36px;
    line-height: 46px;
    //letter-spacing: 0.04em;

    @include media-breakpoint-up(lg) {
      font-size: 40px;
      line-height: 50px;
    }
  }

  .subline {
    font-family: var(--wmf-font-wmfrotis);
    font-weight: $font-weight-medium;
    font-size: 20px;
    line-height: 28px;
    //letter-spacing: 0.04em;

    @include media-breakpoint-up(lg) {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .copy {
    font-family: var(--wmf-font-inter);
    font-weight: $font-weight-light;
    font-size: 14px;
    line-height: 22px;
    //letter-spacing: 0.01em;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-size: 16px;
      line-height: 24px;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-size: 1em;
      font-family: var(--wmf-font-inter);
      font-weight: $font-weight-bold;
    }
  }

  .subline-copy {
    font-family: var(--wmf-font-inter);
    font-weight: $font-weight-bold;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    //letter-spacing: 0.15em;
    padding: 0;
    margin: 0;

    @include media-breakpoint-up(lg) {
      font-weight: $font-weight-extra-bold;
      font-size: 12px;
      line-height: 16px;
    }
  }
}
