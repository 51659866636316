
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                







































































.buy-button {
  display: flex;
  align-items: center;
}

.retailer-button-flyout {
  display: none;
}

@include media-breakpoint-up(lg) {
  .retailer-button-flyout {
    display: block;
  }

  .retailer-button-layer {
    display: none;
  }
}
