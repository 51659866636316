
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                
























































































































































































.header {
  color: white;
  margin-top: var(--wmf-onlineshop-teaser-height);
  position: fixed;
  //width: 100%;
  width: calc(100% - var(--wmf-slide-scroll-bar-width));
  top: 0;
  z-index: $zindex-header;
  max-height: 100%;
  //overflow-y: hidden;
  //overflow-x: hidden;

  .darker {
    content: '';
    position: fixed;
    width: calc(100% - var(--wmf-slide-scroll-bar-width));
    height: 0;
    margin-top: var(--wmf-onlineshop-teaser-height);
    top: 0;
    left: 0;
    background-color: $black;
    opacity: 0;
    transition: opacity 600ms cubic-bezier(0.39, 0.575, 0.565, 1);
    z-index: -1;
  }

  &.show-main-navigation {
    width: 100%;
    overflow: auto;

    .darker {
      height: 100vh;
      opacity: 0.8;
    }
  }
}

.wrapper {
  padding: 30px 0 0;
  width: calc(100% + var(--wmf-slide-scroll-bar-width));
  position: relative;

  &:before,
  &:after {
    content: '';
    transition: opacity;
    opacity: 0;
    background: black;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: opacity 400ms;
    z-index: -1;
  }

  &:after {
    background: linear-gradient(45deg, #2d3340ff -40%, #2d334000 90%);
    width: 50%;
    display: none;

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  .show-main-navigation & {
    &:before,
    &:after {
      opacity: 1;
    }

    @include media-breakpoint-up(lg) {
      width: calc(100% + var(--wmf-header-scroll-bar-width));
    }
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 64px;
}

.left {
  display: flex;
  flex: 0 1 auto;
  width: calc(50% - 100px);
}

.right {
  flex: 0 1 auto;
  width: calc(50% - 100px);
}

.toggle-menu-button {
  font-size: 28px;
  padding: 10px 0;
}

.subnavigation {
  margin-left: 40px;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.logo {
  font-size: 47px;
  line-height: 0;
  color: #fff;

  @include media-breakpoint-up(lg) {
    font-size: 64px;
  }

  a {
    &:hover,
    &:focus {
      color: #fff;
    }
  }
}

.right-content-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .lanuage-selection-button {
    display: none;
    margin-right: 15px;

    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }

  .cta {
    transition: color 300ms;
    display: none;
    white-space: nowrap;
    text-transform: uppercase;
    line-height: 1;

    text-decoration: none;

    &:hover,
    &:focus {
      color: $gray-400;
    }

    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
}

.buy-button {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}
