:root {
  --wmf-font-wmfrotis: #{inspect($font-family-wmfrotis)};

  --wmf-font-inter: #{inspect($font-family-inter)};
  @supports (font-variation-settings: normal) {
    --wmf-font-inter: #{inspect($font-family-inter-var)};
  }
  --wmf-body-font-family: var(--wmf-font-inter) !important;
  --wmf-black: #{$black};
  --wmf-container-padding-x: #{$container-padding-x};
  --wmf-footer-height: #{$footer-height};
  --wmf-slide-scroll-bar-width: 0px;
  --wmf-header-scroll-bar-width: 0px;

  --wmf-content-offset-y: 0px;
  --wmf-onlineshop-teaser-height: 0px;

  @include media-breakpoint-up(lg) {
    --wmf-container-padding-x: 40px;
    --wmf-footer-height: #{$footer-desktop-height};
  }
}

html[lang="gr"] {
  --wmf-font-wmfrotis: #{inspect($font-family-wmfrotis-greek)};
}
