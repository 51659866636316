
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                


















































.debug-grid {
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1000;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.row {
  height: 100%;
}

.content {
  background-color: aquamarine;
  opacity: 0.1;
  height: 100%;
}
