
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                





















































































































































































































































































































































































































































.buy-button {
  position: fixed;
  z-index: $zindex-buy-button;
  right: 26px;
  top: calc(34px + var(--wmf-content-offset-y));
  display: none;

  @include media-breakpoint-up(lg) {
    position: absolute;
    display: block;
    top: 50%;
    transform: translateY(-50%);
  }

  .simple-header & {
    right: 45px;
  }
}
