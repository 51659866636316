
                    @use "sass:math";
                    @import '~bootstrap/scss/functions';
                    @import '~@/assets/styles/variables';
                    @import '~bootstrap/scss/variables';
                    $theme-colors: map-merge($theme-colors, $extended-colors);
                    $spacers: map-merge($spacers, $extended-spacers);
                    @import '~bootstrap/scss/utilities';
                    @import '~bootstrap/scss/mixins';
                









































































































$height: 78px;

.subpage-navigation {
  position: absolute;
  z-index: $zindex-subpagenavigation;
  width: 100%;
  top: 50%;
  transform: translateY(calc(-50% + var(--wmf-content-offset-y)));

  @include media-breakpoint-up(lg) {
    transform: translateY(-50%);
  }

  @include media-breakpoint-only(lg) {
    position: relative;
    top: auto;
    transform: none;
    bottom: $height;
    height: 0;
  }

  @include media-breakpoint-down(lg) {
    padding: 0;
  }

  .row {
    //min-height: $height;
    position: relative;
    width: auto;

    & > div {
      position: relative;
    }
  }

  .btn {
    position: absolute;
    transform: translateY(-50%);
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  &.inline {
    position: absolute;
    width: calc(100% - 100px);
    left: 50px;
    top: 5px !important;

    transform: none;

    display: none;

    .prev {
      left: 0;
      transform: translateX(-100%);
    }

    .next {
      right: 0;
      transform: translateX(100%);
    }

    @include media-breakpoint-up(md) {
      top: 8px !important;
      display: block;
    }

    @include media-breakpoint-up(lg) {
      width: 100%;
      top: 11px !important;
      left: 0;
    }
  }

  &.showOnMobile {
    display: block;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
