@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=c07376ac-a71a-4822-bb69-2819d5f2a398");

//Rotis® II Sans W02 Regular
@font-face {
  font-family: "WMFRotis";
  src: url("~@/assets/fonts/60869336-976a-4b27-9b6f-b3b6a4a997c0.woff2") format("woff2"),
    url("~@/assets/fonts/70ced09f-b88d-47cd-9196-84b47f23ac0b.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

//Rotis® II Sans W02 Italic
@font-face {
  font-family: "WMFRotis";
  src: url("~@/assets/fonts/10653232-0f32-4970-b23c-ef15f845a514.woff2") format("woff2"),
    url("~@/assets/fonts/6ee89341-2a2e-4ee8-a689-55cb6445e8e1.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

//Rotis® II Sans W02 Semi Bold
@font-face {
  font-family: "WMFRotis";
  src: url("~@/assets/fonts/ffaa1a5e-1c2e-4149-9e77-39c452f6dd45.woff2") format("woff2"),
    url("~@/assets/fonts/3d29deb4-bdeb-44fc-8fbb-074892c85dbe.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//Rotis® II Sans W02 Bold
@font-face {
  font-family: "WMFRotis";
  src: url("~@/assets/fonts/1e02a6d8-1bf1-4bb4-9f93-c128b80afe6e.woff2") format("woff2"),
    url("~@/assets/fonts/bf62d42b-4776-49cf-a6c7-fd6526631ce1.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
